<template>
    <div class="frame d-flex flex-column" >
      <div  class="text-h5 font-weight-medium mb-8"><v-icon class="mb-1 mr-1" color="primary">mdi-share-variant-outline</v-icon>Requests</div>
      <router-link :key="item.id" v-for="item in sharedItems" class="text-decoration-none" :to="{name:'SharedWithMeItem', params:{id:item.id}}">
      <v-sheet rounded="" color="white"  class="d-flex flex-row pa-3 mb-3 flex-wrap sharedItem">
        
        <div style="min-width: 300px">
          <div  class="font-weight-bold"><v-icon  class="mr-1" color="primary">mdi-account-injury-outline</v-icon>{{ item.sample.name }}</div>
          <div class="ml-7 mt-n1 grey--text caption">{{ item.requested_at_hr }}</div>
        </div>
        <div style="min-width: 220px" class="mt-1">
          <div>
            <v-icon class="mr-1">mdi-account-network-outline</v-icon>{{ item.user.first_name }} {{ item.user.last_name }}
          </div>
          <div class="ml-7 mt-n1 grey--text caption">{{ item.user.email }}</div>
        </div>
        <div style="min-width: 100px;max-width: 800px;" class="mt-2 d-flex flex-row">
          <v-divider vertical></v-divider>
          <div class="ml-2">
            {{ item.comment }}
          </div>
        </div>
        <div class="mt-2 ml-auto"><v-icon >mdi-chevron-right</v-icon></div>
      </v-sheet>
    </router-link>
      <div v-if="sharedItems.length == 0" class="mx-auto grey--text">Nothing has been shared with you</div>
    </div>

</template>
  <script>
import { getSharedWithMeList } from "@/api/user/collab.js";
import TimeAgo from 'javascript-time-ago'
// English.
import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en)
export default {
  name: "SharedWithMe",
  data: () => ({
    rawSharedItems: [],
  }),
  created() {
    const _this = this;
    getSharedWithMeList((res) => {
      _this.rawSharedItems = res;
    });
  },
  computed: {
    sharedItems() {
      return this.rawSharedItems
        .map((item) => {
            const timeAgo = new TimeAgo('en-US')
          item.requested_at_hr = timeAgo.format(new Date(item.requested_at))//.toLocaleString();
          return item;
        })
        .sort((a, b) => b.id - a.id);
    },
  },
  methods: {},
};
</script>
  <style scoped lang="scss">
.frame {
  border-radius: 16px;
  border: 1px solid #95d9e3;
  // border-image-source: linear-gradient(96.36deg, #9ADBE4 -1.08%, #0393A8 92.31%);
  padding: 30px;
  background: linear-gradient(90deg, rgba(3, 146, 168, 0.09) -59.56%, rgba(3, 146, 168, 0.025) 74.32%);
  border-width: 1px, 1px, 1px, 0px;
  border-style: solid;
  // background: linear-gradient(96.36deg, #9ADBE4 -1.08%, #0393A8 92.31%);
}
.sharedItem{
  box-shadow: 0px 4px 16px rgba(217, 217, 217, 0.5);

}
</style>