<template>
  <div>
    <v-dialog v-model="dialog" min-width="400" max-width="1200" transition="dialog-bottom-transition" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Terms of Use </v-card-title>

        <v-card-text class="pt-8 px-10">
          <TermsAndConditionsContents></TermsAndConditionsContents>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="onAccept"> I Accept </v-btn>
          <v-btn color="primary" text @click="onDecline"> I Don't Accept </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import TermsAndConditionsContents from "@/components/TermsAndConditionsContents.vue"
import { acceptTermsAndConditions, getUser } from "@/api/users.js";
export default {
  name: "TermsAndConditionsWithActions",
  components: { TermsAndConditionsContents },
  data: () => ({
    dialog: false,
  }),
  methods: {
    onAccept() {
      acceptTermsAndConditions(() => {
        this.dialog = false;
      });
    },
    onDecline() {
      this.$store.commit("logout");
      this.$router.push("/login");
    },
  },
  created() {
    // console.log(this.$store.state.UserId);
    getUser(this.$store.state.UserId, (res) => {
      if (res.profile.accepted_terms === null) {
        this.dialog = true;
      }
    });
  },
};
</script>