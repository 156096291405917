<template>
    <div class="ml-8">
        <div class="text-h4 text-center font-weight-bold primary--text">Cohort Analysis Terms</div>

        <div v-for="item in items" :key="item.name" class="mt-8">
            <div class="text-h6 primary--text  font-weight-bold">{{ item.name }}</div>
            <div v-html="item.description" class="mx-4"></div>
        </div>
    </div>    
</template>
<script>
export default {
    name: 'CohortAnalysisHelp',
    data:()=>({
        list:[]
    }),
    created(){
        console.log(this.$root.rawHelp)
    },
    computed:{
        items(){
            return this.$root.rawHelp.filter(item=>item.section=='GENE')
        }
    }
}
</script>