<template>
  <div class="pt-12 px-8">
    <div class="text-h4 font-weight-bold mb-4">
      <icon color="primary" xLarge id="help"></icon>
      Help Center
    </div>
    <div class="d-flex flex-row">
      <v-card width="250" flat >
        <v-navigation-drawer permanent>
          <v-list nav >
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item v-for="(item, i) in items" :key="i" :to="item.link" dense>
                <v-list-item-icon class="mr-4">
                  <v-icon v-text="item.icon" ></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-h6 " v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-card>
      <div style="width: 80%;">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    selectedItem: 0,
    items: [
      { text: "Variant Analysis", icon: "mdi-dna" ,link:{name:'VariantAnalysisHelp'}},
      { text: "Cohort Analysis", icon: "mdi-account-multiple",link:{name:'CohortAnalysisHelp'} },
      { text: "Cuban Plot", icon: "mdi-chart-timeline" ,link:{name:'CubanPlotHelp'}}
    ],
  }),
};
</script>