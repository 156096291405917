<template>
  <div class="pl-8">
    <div class="text-h4 text-center font-weight-bold primary--text">Cuban Plot Architecture</div>
    <v-img class="mx-4" src="@/assets/img/help/cuban-main.png" contain style="width: 95%"> </v-img>

    <div class="mt-8">
      <div class="text-h6 primary--text font-weight-bold">Coverage</div>
      <div class="mx-4">
        Read coverage across the whole structural variant with padding on both sides. Breakpoints are indicated by vertical black dashed lines. Average chromosomal coverage is indicated by a horizontal red dashed line. For the coverage calculation,
        all reads are considered. A red coverage line (not visible in the above example) displays the coverage when only considering reads with a mapping quality > 20.
      </div>
    </div>
    <div class="mt-8 d-flex flex-column">
      <div class="text-h6 primary--text font-weight-bold">Insert Size Outliers distribution</div>
      <div class="mx-4">
        Insert size is the distance between two mates of a read-pair. A higher insert size of read pairs around the breakpoints than expected from the sequencing experiment indicates the presence of a deletion or duplication. The peaks of the line
        plot display the number of reads that have an unusually high insert size (larger than 1 kb). This signal is only relevant for deletions and duplications that are larger than 400-500 bp.
      </div>
      <v-img class="mx-auto mt-2" src="@/assets/img/help/Insert-Size-Outliers-distribution.png" width="500"></v-img>
    </div>
    <div class="mt-8">
      <div class="text-h6 primary--text font-weight-bold">Repeat Elements</div>
      <div class="mx-4">Repetitive sequences in the human genome affect read alignment and variant detection. This track displays all annotated repeat elements that are near the structural variant.</div>
    </div>
    <div class="mt-8 d-flex flex-column">
      <div class="text-h6 primary--text font-weight-bold">Discordant Read Pairs</div>
      <div class="mx-4">
        Due to the nature of short-read-sequencing, it is expected that the first read in a read pair aligns to the forward strand and the second read to the reverse strand. Any deviations from these orientations can indicate the presence of a
        structural variant. The line plot displays the distribution of reads which align in reverse-forward (dark blue), reverse-reverse (orange), and forward-forward (green) orientation. The dashed line displays the distribution of reads which mate
        pair aligns to a different chromosome. Duplications usually contain reads in reverse-forward orientation around the breakpoints, while inversions contain reads in reverse-reverse and forward-forward orientation around the breakpoints. For
        deletions, this signal is not relevant.
      </div>
      <v-img class="mx-auto mt-2" src="@/assets/img/help/Discordant-Read-Pairs.png" width="500"></v-img>
    </div>
    <div class="mt-8">
      <div class="text-h6 primary--text font-weight-bold">Read Alignments</div>
      <div class="mx-4">
        This plot displays the sequencing reads in two individual 200 bp windows around both breakpoints. Color Legend:
        <div v-for="item in readAlignments" :key="item.name" class="ml-10 mt-6">
          <div class="d-flex flex-row">
            <div class="font-weight-medium font-italic" style="width: 200px;">{{ item.name }}</div>
            <v-img class="mr-auto ml-16 my-auto" :src="item.img" :contain="true" max-width="500" max-height="49"></v-img>
          </div>
          {{ item.text }}
        </div>

    </div>



    </div>
    <div class="mt-8">
      <div class="text-h6 primary--text font-weight-bold">Read Connections</div>
      <div class="mx-4">
        Dashed lines between individual reads around the two breakpoints indicate a connection between those reads. Red dashed lines indicate that two reads belong to the same read-pair. If a variant is small and therefore the windows around the two
        breakpoints are overlapping, the same read can be present in the left and right window. These reads are also connected via a red dashed line. Black dashed lines connect two segments of a split-read. These lines, connecting two split-reads on
        both sides of the breakpoint, serve as additional evidence for the correct identification of SV breakpoints. For larger variants, red dashed lines, indicating a connection between two mates serve, similar to the insert-size outlier track, as
        evidence for the presence of an SV.
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CubanPlotHelp",
  created() {
    console.log(this.$root.rawHelp);
  },
  data:()=>({
    readAlignments:[
        {
            name:'Light Gray',
            img:require('../../../assets/img/help/read-alignment-Light-Gray.png'),
            text:'Normally aligned read'
        },
        {
            name:'Dark Gray',
            img:require('../../../assets/img/help/read-alignment-Dark-Gray.png'),
            text:'Read with mapping quality < 30, indicates that the read aligns to multiple positions in the reference genome'
        },
        {
            name:'Blue',
            img:require('../../../assets/img/help/read-alignment-Blue.png'),
            text:'Small deleted segment'
        },
        {
            name:'Yellow',
            img:require('../../../assets/img/help/read-alignment-Yellow.png'),
            text:'Small inserted segment'
        },
        {
            name:'Green',
            img:require('../../../assets/img/help/read-alignment-Green.png'),
            text:'Read is soft-clipped, only some portion of the read aligns to the reference, can indicate the presence of a breakpoint. The light gray part aligns normally and the green part is clipped, i.e., not aligned'
        },
        {
            name:'Red',
            img:require('../../../assets/img/help/read-alignment-Red.png'),
            text:'Read is hard-clipped, only some portion of the read aligns to the reference, can indicate the presence of a breakpoint.  The light gray part aligns normally and the red part is clipped, i.e., not aligned'
        },
        {
            name:'Black grid overlay',
            img:require('../../../assets/img/help/read-alignment-Black-grid-overlay.png'),
            text:'Read is split, one part of the read aligns to a certain position in the reference genome and the other part of the read aligns to a different position.'
        },
        {
            name:'Dark blue overlay',
            img:require('../../../assets/img/help/read-alignment-Dark-blue-overlay.png'),
            text:'Read is in reverse-forward orientation'
        },
        {
            name:'Orange overlay',
            img:require('../../../assets/img/help/read-alignment-Orange-overlay.png'),
            text:'Read is in reverse-reverse orientation'
        },
        {
            name:'Green overlay',
            img:require('../../../assets/img/help/read-alignment-Green-overlay.png'),
            text:'Read is in forward-forward orientation'
        },
    ]
  }),
  computed: {
    items() {
      return this.$root.rawHelp.filter((item) => item.section == "GENE");
    },
  },
};
</script>