<template>
  <div class="pa-12">
    <div class="text-h4 font-weight-bold mb-4">
      <icon color="primary" xLarge id="collab"></icon>
      Collaborative Analysis
    </div>

    <router-view></router-view>

  </div>

</template>
  <script>
export default {
  name: "CollaborativeAnalysis",
  data: () => ({
  }),
  created() {
  },
  computed: {
  },
  methods: {},
};
</script>
  <style scoped lang="scss">
</style>