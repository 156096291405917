<template>
  <div class="pa-12">
    <div class="text-h4 font-weight-bold mb-4">
      <v-icon x-large class="mb-2" color="primary">mdi-account-group-outline</v-icon>
      COHORTS
    </div>
    <div class="frame">
      <v-data-table
        :headers="headers"
        hide-default-header
        :hide-default-footer="cohorts.length < 15"
        :items-per-page="15"
        :items="cohorts"
        sort-by="name"
        class="elevation-0 cohortTable"
        :single-expand="true"
        :expanded.sync="expanded"
        single-select
        @click:row="onRowClick"
        item-key="name"
        show-expand
        @item-expanded="showCohort"
      >
        <template v-slot:expanded-item="{ headers }">
          <td :colspan="headers.length" class="px-8 pb-4 pt-4 cohortTableCellContent">
            <v-row v-for="item in samples" :key="item.name">
              <v-col cols="2" class="grey--text text--darken-2 pt-4">Name: <span class="font-weight-medium ml-2"> {{ item.name }} </span></v-col>
              <v-col cols="2" class="grey--text text--darken-2 pt-4">Disease: <span class="font-weight-medium ml-2"> {{ item.disease.name }} </span></v-col>
              <v-col cols="2" class="grey--text text--darken-2 pt-4">Technology: <span class="font-weight-medium ml-2"> {{ item.technology.name }} </span></v-col>
              <v-col cols="6" class="d-flex justify-end flex-wrap ">
                <router-link v-if="item.hasData" :to="{ name: 'LucidAllAnalysisCohortOverview', params: { id: item.id, cohortid: selectedCohort.id } }" class="text-decoration-none">
                  <v-tooltip top :open-delay="300">
                    <template v-slot:activator="{ on }">
                      <v-btn class="font-weight-bold mr-4" width="200px" outlined color="primary" v-on="on"><icon small class="mr-2" id="machine-learning-model"></icon> Analysis  </v-btn>
                    </template>
                    <span
                      >LUCID Analysis <strong>{{ item.name }}</strong>
                    </span>
                  </v-tooltip>
                </router-link>
                <router-link :to="{ name: 'UploadSequenceData', params: { id: item.id, cohortid: selectedCohort.id } }" class="text-decoration-none">
                  <v-tooltip top :open-delay="300">
                    <template v-slot:activator="{ on }">
                      <v-btn class="font-weight-bold" width="200px" outlined color="primary" v-on="on"><v-icon class="mr-2">mdi-cloud-upload-outline</v-icon> Upload Data  </v-btn>
                    </template>
                    <span
                      >Upload FASTQ files for <strong>{{ item.name }}</strong>
                    </span>
                  </v-tooltip>
                </router-link>
              </v-col>
            </v-row>
            <!-- <v-data-table :headers="sampleHeaders" :hide-default-footer="samples.length < 15" :items-per-page="15" :items="samples" sort-by="name" class="elevation-0">
              <template v-slot:item.actions="{ item }">
                <div class="d-flex flex-row">
                  <router-link v-if="item.hasData" :to="{ name: 'LucidAllAnalysisCohortOverview', params: { id: item.id, cohortid: selectedCohort.id } }" class="text-decoration-none">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn class="font-weight-bold" text color="primary" v-on="on">Analysis <v-icon class="mt-1">mdi-chevron-right</v-icon> </v-btn>
                      </template>
                      <span
                        >LUCID All Analysis <strong>{{ item.name }}</strong>
                      </span>
                    </v-tooltip>
                  </router-link>
                  <router-link :to="{ name: 'UploadSequenceData', params: { id: item.id, cohortid: selectedCohort.id } }" class="text-decoration-none">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn class="font-weight-bold" text color="primary" v-on="on">Upload Data <v-icon class="mt-1">mdi-chevron-right</v-icon> </v-btn>
                      </template>
                      <span
                        >Upload FASTQ files for <strong>{{ item.name }}</strong>
                      </span>
                    </v-tooltip>
                  </router-link>
                </div>
              </template>
              <template v-slot:no-data> NO SAMPLES </template>
            </v-data-table> -->
          </td>
        </template>
        <template v-slot:no-data> There is no data to show </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { getCohorts, getDatasets } from "@/api/user/dataset.js";
import { getSampleStatuses, getSampleTechnologies } from "@/api/sample.js";

export default {
  name: "UserCohorts",
  data: () => ({
    expanded: [],
    panel: 0,
    headers: [
      { text: "NAME", value: "name", cellClass: " rounded-tl font-weight-bold " },
      { text: "SAMPLE SIZE", value: "size" },
      { text: "DETAILS", value: "details" },
      { text: "", value: "data-table-expand", cellClass: " rounded-tr", width: "40px" },
    ],
    cohorts: [],
    selectedCohort: {
      name: "",
    },
    samples: [],
    sampleHeaders: [
      { text: "PROJECT NAME", value: "name", align: "start", class: "primary white--text font-weight-regular rounded-l sampleHeader", cellClass: "sampleCell" },
      { text: "DISEASE", value: "disease.name", class: "primary  white--text font-weight-regular sampleHeader", cellClass: "sampleCell" },
      { text: "TECHNOLOGY", value: "technology.name", class: "primary white--text font-weight-regular sampleHeader", cellClass: "sampleCell" },
      { text: "ANALYSES", value: "actions", sortable: false, width: "125px", align: "center", class: "primary  white--text font-weight-regular rounded-r sampleHeader", cellClass: "sampleCell" },
    ],
    sampleStatuses: [],
    sampleTechnologies: [],
  }),
  created() {
    const _this = this;
    getSampleStatuses(function (res) {
      _this.sampleStatuses = res;
    });
    getSampleTechnologies(function (res) {
      _this.sampleTechnologies = res;
    });

    this.loadCohorts();
  },
  methods: {
    onRowClick(item, row) {
      if (this.expanded.length >= 0 && this.expanded[0] == item) {
        this.expanded = [];
      } else {
        this.expanded = [];
        row.select(true);
        this.$nextTick(() => {
          this.expanded = [item];
          this.showCohort({ value: true, item: item });
        });
      }
    },
    loadCohorts() {
      const _this = this;
      getCohorts(function (res) {
        _this.cohorts = res.map((item) => {
          let x = item;
          x.size = `Size: ${item.size}`;
          return x;
        });
        if (_this.cohorts.length == 1) {
          _this.$nextTick(() => {
            _this.expanded = [_this.cohorts[0]];
            _this.showCohort({ value: true, item: _this.cohorts[0] });
          });
        }
      });
    },
    showCohort(selected) {
      if (selected.value) {
        this.selectedCohort = selected.item;
        const _this = this;
        getDatasets(this.selectedCohort.id, function (res) {
          let sampleIds = new Set();
          _this.samples = [];
          res.datasets.forEach((item) => {
            if (!sampleIds.has(item.sample.id)) {
              sampleIds.add(item.sample.id);
              const sm = item.sample;
              sm.technology = _this.sampleTechnologies.find((st) => (st.id = item.sample.technology));
              sm.sampleStatus = _this.sampleStatuses.find((st) => (st.id = item.sample.sampleStatus));
              sm.hasData = item.status.id == 50;
              _this.samples.push(item.sample);
            }
          });
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.v-data-table::v-deep .v-data-table__expanded__content {
  box-shadow: none !important;
}
.cohortTableCellContent {
  border: 1px solid white;
  border-top-width: 0px;
  border-radius: 0px 0px 8px 8px;
  margin-bottom: 10px;
}
.theme--light.v-data-table::v-deep {
  background-color: transparent;
}

.cohortTable.theme--light.v-data-table::v-deep > .v-data-table__wrapper > table {
  height: 35px !important;
  border-spacing: 0px 10px !important;
}

.cohortTable.theme--light.v-data-table::v-deep > .v-data-table__wrapper > table > tbody > tr > td {
  border-bottom: 1px solid white !important;
}

.theme--light.v-data-table::v-deep > .v-data-table__wrapper > table > tbody > tr > td.sampleCell {
  height: 35px !important;
  // border-spacing: 10px 10px !important;
  border-bottom: 1px solid var(--v-primary-base) !important;
}
.theme--light.v-data-table::v-deep > .v-data-table__wrapper > table > tbody > tr {
  box-shadow: 0px 4px 16px rgba(217, 217, 217, 0.3);
}
.theme--light.v-data-table::v-deep .v-data-footer {
  border-top: 0px !important;
}

.theme--light.v-data-table::v-deep > .v-data-table__wrapper > table > thead > tr > th.sampleHeader {
  height: 35px !important;
}

.cohortTable.theme--light.v-data-table::v-deep > .v-data-table__wrapper > table > tbody > tr:not(.v-data-table__expanded__content):hover {
  cursor: pointer;
  background-color: #e9e9e9 !important;
}

.cohortTable.theme--light.v-data-table::v-deep > .v-data-table__wrapper > table > tbody > tr:not(.v-data-table__expanded__content) {
  background-color: white !important;
}

.frame {
  border-radius: 16px;
  border: 1px solid #95d9e3;
  // border-image-source: linear-gradient(96.36deg, #9ADBE4 -1.08%, #0393A8 92.31%);
  padding: 30px;
  background: linear-gradient(90deg, rgba(3, 146, 168, 0.09) -59.56%, rgba(3, 146, 168, 0.025) 74.32%);
  border-width: 1px, 1px, 1px, 0px;
  border-style: solid;
  // background: linear-gradient(96.36deg, #9ADBE4 -1.08%, #0393A8 92.31%);
}
</style>