import ApiIntegrface from "./api"

export function getUIVersionInfo (callback) {
    fetch('version.info')
      .then(response => response.json())
      .then(data => { callback(data) })
}
  
export function getBackVersionInfo( callback){
    const api = new ApiIntegrface()
    const url = '/api/v1/health'
    api.get(url, null, callback)
}